  export class apiUrl {


    // region login
    public static get Login(): string { return "Authenticate"; }
    public static get CheckToken(): string { return "Authenticate/CheckToken"; }

    // end
    // region organization
    public static get OrganizationGetAll(): string { return "Organizations/GetOrganizations/"; }
    public static get OrganizationGetById(): string { return "Organizations"; }
    public static get OrganizationSave(): string { return "Organizations"; }
    public static get OrganizationDelete(): string { return "Organizations"; }
    public static get OrganizationGetAllForDropdown(): string { return "Organizations/GetdlOrganizations/"; }
    public static get GetOrganisationbyBrandId(): string { return "Brands/GetOrganisationbyBrandId/"; }

    // end

    
    // region organization
    public static get CompanyGetAll(): string { return "Companies"; }
    public static get CompanyGetById(): string { return "Companies"; }
    public static get CompanyGetAllForDropdown(): string { return "Companies/GetdlCompanies"; }
    public static get CompanySave(): string { return "Companies"; }
    public static get CompanyDelete(): string { return "Companies"; }
    // end


    // region Cameras
    public static get CameraGetAll(): string { return "Cameras?"; }
    public static get CameraGetById(): string { return "Cameras"; }
    public static get CameraSave(): string { return "Cameras"; }
    public static get CameraDelete(): string { return "Cameras"; }
    public static get CameraGetAllForDropDown(): string { return "Cameras/GetdlCameras/"; }
    public static get CameraGetAllForDropDownByOrganization(): string { return "Cameras/GetdlOrganizationCameras/"; }
    
    
    
    // region Brands
    public static get BrandGetAll(): string { return "Brands?"; }
    public static get BrandGetById(): string { return "Brands"; }
    public static get BrandSave(): string { return "Brands"; }
    public static get BrandDelete(): string { return "Brands"; }
    public static get BrandGetAllForDropDown(): string { return "Brands/GetdlBrands/"; }
    public static get BrandGetAllForDropDownByOrganization(): string { return "Brands/GetdlOrganizationBrands/"; }


    public static get CameraEventMappingInsert():string { return "Cameras/CameraEventMappingInsert";}
      public static get CameraEventMappingGet():string { return "Cameras/GetCameraEventMapping/";}
      public static get CameraSchedulesGet():string { return "Cameras/GetCameraSchedules/";}
      public static get CameraSchedulesInsert():string { return "Cameras/InsertCameraSchedule";}

    // end

    // region Employees
    public static get EmployeeGetAll(): string { return "Employees"; }
    public static get EmployeeGetById(): string { return "Employees"; }
    public static get EmployeeSave(): string { return "Employees"; }
    public static get EmployeeDelete(): string { return "Employees"; }
    public static get EmployeeGetAllForDropdown(): string { return "Employees/GetdlEmployees"; }
    public static get EmployeeGetAllByOrganization(): string { return "Employees/GetEmployees"; }

    // end


    // region Packages
    public static get PackagesGetAll(): string { return "Packages"; }
    public static get PackagesGetById(): string { return "Packages"; }
    public static get PackagesSave(): string { return "Packages"; }
    public static get PackagesDelete(): string { return "Packages"; }
    public static get PackagesGetAllForDropdown(): string { return "Packages/GetdlPackages"; }

    // end


    // region Plans
    public static get PlansGetAll(): string { return "Plans"; }
    public static get PlansGetById(): string { return "Plans"; }
    public static get PlansSave(): string { return "Plans"; }
    public static get PlansDelete(): string { return "Plans"; }
    public static get PlansGetAllForDropdown(): string { return "Plans/GetdlPlans"; }

    // end
    // region Subscriptions
    public static get SubscriptionsGetAll(): string { return "Subscriptions"; }
    public static get SubscriptionsGetById(): string { return "Subscriptions"; }
    public static get SubscriptionsSave(): string { return "Subscriptions"; }
    public static get SubscriptionsDelete(): string { return "Subscriptions"; }
    public static get SubscriptionsGetAllForDropdown(): string { return "Subscriptions/GetdlSubscriptions"; }

    // end


    // region Roles
    public static get RoleGetAll(): string { return "Roles"; }
    public static get RoleGetById(): string { return "Roles"; }
    public static get RoleSave(): string { return "Roles"; }
    public static get RoleDelete(): string { return "Roles"; }
    public static get RoleGetAllForDropdown(): string { return "Roles/GetdlRoles"; }

    // end
    
    // region Rooms
    public static get RoomGetAll(): string { return "Rooms?"; }
    public static get RoomGetById(): string { return "Rooms"; }
    public static get RoomSave(): string { return "Rooms"; }
    public static get RoomDelete(): string { return "Rooms"; }
    public static get RoomGetAllForDropdown(): string { return "Rooms/GetdlRooms/"; }
    public static get RoomTypesGetAll(): string { return "Rooms/GetdlRoomTypes"; }

    // end
    
    // region Users
    public static get UserGetAll(): string { return "Users?"; }
    public static get UserGetById(): string { return "Users"; }
    public static get UserSave(): string { return "Users"; }
    public static get UserDelete(): string { return "Users"; }
    public static get UserGetAllForDropdown(): string { return "Users/GetdlUsers/"; }

    // end 
    
    // region Floors
    public static get FloorGetAll(): string { return "Floors?"; }
    public static get FloorGetById(): string { return "Floors"; }
    public static get FloorGetAllForDropdown(): string { return "Floors/GetdlFloors/"; }
    public static get FloorsSave(): string { return "Floors"; }
    public static get FloorsDelete(): string { return "Floors"; }

    public static get GetCameraPositionData(): string { return "Floors/GetCameraPositionData/"; }
    public static get PostCameraPositionData(): string { return "Floors/PostCameraPositionData"; }

    // end


    // region Floors
    public static get AssetDocumentGetAll(): string { return "AssetDocuments/GetAssetDocuments"; }
    public static get AssetDocumentGetById(): string { return "AssetDocuments"; }
    public static get AssetTypesGetAllForDropdown(): string { return "AssetDocuments/GetdlAssetTypes"; }
    public static get AssetDocumentsSave(): string { return "AssetDocuments"; }
    public static get AssetDocumentsUpdate(): string { return "AssetDocuments"; }
    public static get AssetDocumentsDelete(): string { return "AssetDocuments"; }

    //end


      // region EventGroup
      public static get EventGroupGetAll(): string { return "EventGroups"; }
      public static get EventGroupGetById(): string { return "EventGroups"; }
      public static get EventGroupGetAllForDropdown(): string { return "EventGroups/GetdlEventGroups"; }
      public static get EventGroupSave(): string { return "EventGroups"; }
      public static get EventGroupDelete(): string { return "EventGroups"; }
      public static get EventGroupMapping():string { return "EventGroups/EventGroupMappingInsert";}
      public static get EventGroupMappinglist():string { return "EventGroups/GetEventGroupMapping/";}
      

      // end


      // region EventType
      public static get EventTypeGetAll(): string { return "EventTypes"; }
      public static get EventTypeGetById(): string { return "EventTypes"; }
      public static get EventTypeGetAllForDropdown(): string { return "EventTypes/GetdlEventTypes"; }
      public static get EventTypeSave(): string { return "EventTypes"; }
      public static get EventTypeDelete(): string { return "EventTypes"; }



      // region EventType
      public static get CheckListGetAll(): string { return "CheckLists"; }
      public static get GetdlCompanyCheckList(): string { return "CheckLists/GetdlCompanyCheckList"; }
      public static get GetdlCompanyCheckListData(): string { return "CheckLists/GetdlCompanyCheckListData/"; }
      public static get CheckListGetById(): string { return "CheckLists"; }
      public static get CheckListGetAllForDropdown(): string { return "CheckLists/GetdlCheckLists"; }
      public static get CheckListSave(): string { return "CheckLists"; }
      public static get CheckListDelete(): string { return "CheckLists"; }

      public static get CheckListTypeGetAllForDropdown(): string { return "CheckLists/GetdlCheckListType"; }

      // end
      public static get GetdlGroupCheckListData(): string { return "CheckLists/GetdlGroupCheckListData"; }

      public static get GetdlDefaultChecklistGroups():string {return "CheckListGroups/GetdlDefaultCheckListGroups"}

      public static get GetCheckListData(): string { return "CheckListForms/GetCheckListData/"; }
      public static get InsertCheckListForm(): string { return "CheckListForms/InsertCheckListForm"; }

      public static get GetdlGroupCompanyCheckList(): string { return "CheckLists/GetdlGroupCompanyCheckList"; }
 
      

      public static get CheckListTitleGetAll(): string { return "CheckListTitles"; }
      public static get GetdlCheckListTitle(): string { return "CheckListTitles/GetdlCheckListTitle/"; }
      public static get CheckListTitleGetById(): string { return "CheckListTitles"; }
      public static get CheckListTitleGetAllForDropdown(): string { return "CheckListTitles/GetdlCheckListTitles"; }
      public static get CheckListTitleSave(): string { return "CheckListTitles"; }
      public static get CheckListTitleDelete(): string { return "CheckListTitles"; }
    
    
    
      public static get CheckListAnswerGetAll(): string { return "CheckListAnswers"; }
      public static get GetdlCheckListAnswer(): string { return "CheckListAnswers/GetdlCheckListAnswer/"; }
      public static get CheckListAnswerGetById(): string { return "CheckListAnswers"; }
      public static get CheckListAnswerGetAllForDropdown(): string { return "CheckListAnswers/GetdlCheckListAnswers"; }
      public static get CheckListAnswerSave(): string { return "CheckListAnswers"; }
      public static get CheckListAnswerDelete(): string { return "CheckListAnswers"; }
   
   
   
      public static get CheckListGroupGetAll(): string { return "CheckListGroups"; }
      public static get GetdlCheckListGroup(): string { return "CheckListGroups/GetdlCheckListGroups/"; }
      public static get CheckListGroupGetById(): string { return "CheckListGroups"; }
      public static get CheckListGroupGetAllForDropdown(): string { return "CheckListGroups/GetdlCheckListGroups"; }
      public static get CheckListGroupSave(): string { return "CheckListGroups"; }
      public static get CheckListGroupDelete(): string { return "CheckListGroups"; }
      public static get DefaultChecklists(): string { return "CheckLists/GetdlDefaultCheckLists"; }
      public static get MoveChecklist():string {return "CheckLists/MoveCheckList?"}
      public static get DefaultCheckListAnswer(): string {return 'CheckListAnswers/GetdlDefaultCheckListAnswer'}



      public static get GetCheckListQuestionbyTitleId(): string { return "CheckListQuestions/GetCheckListQuestionbyTitleId/"; }
      public static get CheckListQuestionGetAll(): string { return "CheckListQuestions"; }
      public static get GetdlCheckListQuestion(): string { return "CheckListQuestions/GetdlCheckListQuestion/"; }
      public static get CheckListQuestionGetById(): string { return "CheckListQuestions"; }
      public static get CheckListQuestionGetAllForDropdown(): string { return "CheckListQuestions/GetdlCheckListQuestions"; }
      public static get CheckListQuestionSave(): string { return "CheckListQuestions"; }
      public static get CheckListQuestionDelete(): string { return "CheckListQuestions"; }



      // region Dashboard
      public static get DashboardConnectedDatas(): string { return "Dashboard/GetConnectedData"; }
      // end


      // region ViolationDashboard
      public static get GetTokenByStatus (): string { return "Tokens/TokensbyStatus" }
      public static get GetTokensCountbyStatus (): string { return "Tokens/GetTokensCountbyStatus" }

      public static get GetAssignedTokens (): string { return "Tokens/AssignedTokens" }

      public static get TokenEmployeesMapping (): string { return "Tokens/TokenEmployeesMapping" }
      public static get GetTokenNotes (): string { return "TokenNotes/GetTokenNotes/" }
      public static get GetToken (): string { return "Tokens/" }
      public static get GetTokenStatusLog (): string { return "Tokens/GetTokenStatusLog/" }


      public static get GetDashboardEventData (): string { return "Dashboard/GetEventData" } 
      public static get GetEventTypeCountReportbyFilter (): string { return "Dashboard/GetEventTypeCountReportbyFilter" } 

      public static get GetTokenStatusCountReport (): string { return "Dashboard/GetTokenStatusCountReport" }
      public static get GetUserTokenStatusCountReport (): string { return "Dashboard/GetUserTokenStatusCountReport" }


      public static get GetDashboardTokenStatusData (): string { return "Dashboard/GetTokenStatusData/" }

      public static get GetTokenStatus (): string { return "Tokens/GetTokenStatus" }

      public static get UpdateTokenStatus (): string { return "Tokens/UpdateTokenStatus/" }


      public static get GetEventMonthlyChart (): string { return "Dashboard/GetEventMonthlyChart" }
      public static get GetMonthlyChartbyFilter (): string { return "Dashboard/GetMonthlyChartbyFilter" }


      public static get GetEmployeeReport (): string { return "Dashboard/GetEmployeeReport" }

      public static get GetEmployeeViolationChart (): string { return "Dashboard/GetEmployeeViolationChart" }

      public static get GetCameraViolationPieChartReport (): string { return "Dashboard/GetCameraViolationPieChartReport" }
      public static get GetFloorViolationPieChartReport (): string { return "Dashboard/GetFloorViolationPieChartReport" }



      public static get GetTokenReport (): string { return "Dashboard/GetTokenReport" }
      public static get GetCheckListForm (): string { return "CheckListForms/GetCheckListForm" }


      public static get GetMonthlyChartbyEventType (): string { return "Dashboard/GetMonthlyChartbyEventType/" }







      public static get GetViolatedEmployees (): string { return "TokenNotes/GetViolatedEmployees/" }
      public static get GetTokenAssignedUsers (): string { return "Tokens/GetAssignedUsers/" }

      public static get ViolatedEmployees (): string { return "TokenNotes/ViolatedEmployees" }
      public static get TokenNotes (): string { return "TokenNotes" }




      // end



      // region Maintenance Rule
    public static get RuleGetAll(): string { return "Rules"; }
    public static get RuleGetById(): string { return "Rules"; }
    public static get RuleSave(): string { return "Rules"; }
    public static get RuleDelete(): string { return "Rules"; }



    

    public static get RuleGetAllForDropdown(): string { return "Rules/GetdlRules"; }
    public static get GetCameraRuleMapping(): string { return "Rules/GetCameraRuleMapping/"; }
    public static get InsertCameraRuleMapping(): string { return "Rules/CameraRuleMappingInsert"; }



    public static get GetBrandOrganisationMapping(): string { return "Brands/GetBrandOrganisationMapping/"; }
    public static get BrandOrganisationMappingInsert(): string { return "Brands/BrandOrganisationMappingInsert"; }


    // end
      // region Maintenance MAintencance
    public static get MaintenanceGetAll(): string { return "Maintenances"; }
    public static get MaintenanceGetById(): string { return "Maintenances"; }
    public static get MaintenanceSave(): string { return "Maintenances"; }
    public static get MaintenanceDelete(): string { return "Maintenances"; }


    // end




        
      public static get GetUnReadCheckListForm(): string { return "CheckListForms/GetUnReadCheckListForm"; }
      public static get UpdateCheckListFormRead(): string { return "CheckListForms/UpdateCheckListFormRead/"; }
       
      public static get GetCheckListFormById(): string { return "CheckListForms/GetCheckListFormById"; }

        

  } 



  export class apiUrlExternal {
    public static get EventTypeGet(): string { return "https://ec2-3-111-171-19.ap-south-1.compute.amazonaws.com/api/EventList/EventList"; }
    public static get CameraGet(): string { return "https://ec2-3-111-171-19.ap-south-1.compute.amazonaws.com/api/CameraList/CameraList"; }
  }
