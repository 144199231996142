import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ILoginResponse } from 'src/app/auth/login/login.model';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;
	
	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;


	public userDetails : ILoginResponse;
	constructor(private router: Router) {
		
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}



		// this.userDetails = JSON.parse(localStorage.getItem('currentUser'));
		// if(this.userDetails.roleId == 1){
		// 	this.MENUITEMS = this.SUPERADMIN_MENU_ITEMS;
		// }
		// if(this.userDetails.roleId == 2){
		// 	this.MENUITEMS = this.COMPANYADMIN_MENU_ITEMS;
		// }

		// if(this.userDetails.roleId == 3){
		// 	this.MENUITEMS = this.STOREADMIN_MENU_ITEMS;
		// }
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	SUPERADMIN_MENU_ITEMS :Menu[]= [
		// {
		// 	headTitle1: 'General', headTitle2: 'Ready Sample Page.',
		// },
		{
			path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link', bookmark: true
		},
		// {
		// 	path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link', bookmark: true
		// },
		{
			title: 'Configurations', icon: 'message-square', type: 'sub', children: [
				{ path: '/masters/company', title: 'Company', type: 'link' },
	        	{ path: '/masters/organization', title: 'Organization', type: 'link' },
				{ path: '/masters/floor', title: 'Floor', type: 'link' },
				{ path: '/masters/room', title: 'Room', type: 'link' },
				{ path: '/masters/camera', title: 'Camera', type: 'link' },
				{ path: '/masters/user', title: 'User', type: 'link' },
				{ path: '/masters/event-group', title: 'Event Group', type: 'link' },
				{ path: '/masters/event-type', title: 'Event Type', type: 'link' },
			]
		},
		{
			title: 'Checklist', icon: 'message-square', type: 'sub', children: [
				// { path: '/check-list/check-list-titles', title: 'CheckListTitles', type: 'link' },
				{ path: '/check-list', title: 'CheckLists', type: 'link' },
				{ path: '/check-list/check-list-group', title: 'CheckList Group', type: 'link' },
				{ path: '/check-list/check-list-answer-type', title: 'Answer Type', type: 'link' },
				{ path: '/check-list/check-list-report', title: 'Report', type: 'link' },
				{ path: '/check-list/move-check-list', title: 'Map Checklist', type: 'link' },
				// { path: '/check-list/check-list-qstns', title: 'CheckListQuestions', type: 'link' },
			]
		},
		{
			title: 'Subscription', icon: 'message-square', type: 'sub', children: [
				{ path: '/subscription/plan', title: 'Plan', type: 'link' },
				{ path: '/subscription/package', title: 'Package', type: 'link' },
				{ path: '/subscription', title: 'Subscription', type: 'link' },


			]
		},


		// {
		// 	title: 'Checklist', icon: 'message-square', type: 'sub', children: [
		// 		// { path: '/check-list/check-list-titles', title: 'CheckListTitles', type: 'link' },
		// 		{ path: '/check-list', title: 'CheckLists', type: 'link' },
		// 		{ path: '/check-list/check-list-answer-type', title: 'Answer Type', type: 'link' },
		// 		// { path: '/check-list/check-list-report', title: 'Report', type: 'link' },
				
		// 		// { path: '/check-list/check-list-qstns', title: 'CheckListQuestions', type: 'link' },
				

		// 	]
		// },

		
	

		// {
		// 	title: 'Settings', icon: 'message-square', type: 'sub', badgeType: 'success', badgeValue: '2', children: [
		// 		{ path: '/settings/camera-event-group-time-schedule', title: 'Scehdule', type: 'link' },
		// 	]
		// },
		
	];
	COMPANYADMIN_MENU_ITEMS :Menu[]= [
		// {
		// 	headTitle1: 'General', headTitle2: 'Ready Sample Page.',
		// },
		// {
		// 	path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link', bookmark: true,
		// },
		{
			path: '/check-list-dashboard', title: 'Dashboard', icon: 'home', type: 'link', bookmark: true
		},
		{
			title: 'Configurations', icon: 'message-square', type: 'sub', children: [
	        	{ path: '/masters/organization', title: 'Organization', type: 'link' },
	        	{ path: '/masters/brand', title: 'Brand', type: 'link' },
				// { path: '/masters/floor', title: 'Floor', type: 'link' },
				// { path: '/masters/room', title: 'Room', type: 'link' },
				// { path: '/masters/camera', title: 'Camera', type: 'link' },
				{ path: '/masters/user', title: 'User', type: 'link' },
			]
		},

		
		{
			title: 'Checklist', icon: 'message-square', type: 'sub', children: [
				// { path: '/check-list/check-list-titles', title: 'CheckListTitles', type: 'link' },
				{ path: '/check-list', title: 'CheckLists', type: 'link' },
				{ path: '/check-list/check-list-group', title: 'CheckList Group', type: 'link' },
				{ path: '/check-list/check-list-answer-type', title: 'Answer Type', type: 'link' },
				{ path: '/check-list/check-list-report', title: 'Report', type: 'link' },
				// { path: '/check-list/check-list-qstns', title: 'CheckListQuestions', type: 'link' },
			]
		},

		
	];
	STOREADMIN_MENU_ITEMS :Menu[]= [
		// {
		// 	headTitle1: 'General', headTitle2: 'Ready Sample Page.',
		// },
		// {
		// 	path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link', bookmark: true
		// },
		{
			path: '/check-list-dashboard', title: 'Dashboard', icon: 'home', type: 'link', bookmark: true
		},
		// {
		// 	path: '/violation-dashboard', title: 'Violation', icon: 'book', type: 'link', bookmark: true
		// },
		// {
		// 	path: '/check-list-builder', title: 'Check List Builder', icon: 'book', type: 'link', bookmark: true
		// },
		// {
		// 	path: '/floor-layout', title: 'Floor Layout', icon: 'book', type: 'link', bookmark: true
		// },
		// {
		// 	path: '/masters/asset-documents', title: 'Asset Documents', icon: 'book', type: 'link', bookmark: true
		// },
		// {
		// 	path: '/reports/token-report', title: 'Token Report', icon: 'book', type: 'link', bookmark: true
		// },
		// {
		// 	path: '/reports/employee-report', title: 'Employee Report', icon: 'book', type: 'link', bookmark: true
		// },
		// {
		// 	title: 'Maintenance', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
		// 		{ path: '/maintenance', title: 'Maintenance', type: 'link' },
		// 		{ path: '/maintenance/rule', title: 'Rule', type: 'link' },
			
		// 	]
		// },
		// {
		// 	title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
		// 		{ path: '/reports/token-report', title: 'Token Report', type: 'link' },
		// 		{ path: '/reports/employee-report', title: 'Employee Report', type: 'link' },
			
		// 	]
		// },
		{
			title: 'Checklist', icon: 'message-square', type: 'sub', children: [
				// { path: '/check-list/check-list-titles', title: 'CheckListTitles', type: 'link' },
				{ path: '/check-list', title: 'CheckLists', type: 'link' },
				{ path: '/check-list/check-list-group', title: 'CheckList Group', type: 'link' },

				{ path: '/check-list/check-list-report', title: 'Report', type: 'link' },

				// { path: '/check-list/check-list-answer-type', title: 'Answer Type', type: 'link' },
				// { path: '/check-list/check-list-report', title: 'Report', type: 'link' },
				// { path: '/check-list/check-list-qstns', title: 'CheckListQuestions', type: 'link' },
			]
		},
		{
			title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success',  children: [
				// { path: '/masters/floor', title: 'Floor', type: 'link' },
				// { path: '/masters/room', title: 'Room', type: 'link' },
				// { path: '/masters/camera', title: 'Camera', type: 'link' },
				// { path: '/masters/employee', title: 'Employee', type: 'link' },
				{ path: '/masters/user', title: 'User', type: 'link' },
			]
		},
		
	];
	INSPECTOR_MENU_ITEMS :Menu[]= [
		// {
	
		{
			title: 'Checklist', icon: 'message-square', type: 'sub', children: [
				{ path: '/check-list', title: 'CheckLists', type: 'link' },
				// { path: '/check-list/check-list-report', title: 'Report', type: 'link' },
			]
		},
		
		
	];
	BRAND_MENU_ITEMS :Menu[]= [
			// {
		// 	headTitle1: 'General', headTitle2: 'Ready Sample Page.',
		// },
		// {
		// 	path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link', bookmark: true
		// },
		{
			path: '/check-list-dashboard', title: 'Dashboard', icon: 'home', type: 'link', bookmark: true
		},
		// {
		// 	path: '/violation-dashboard', title: 'Violation', icon: 'book', type: 'link', bookmark: true
		// },
		// {
		// 	path: '/check-list-builder', title: 'Check List Builder', icon: 'book', type: 'link', bookmark: true
		// },
		// {
		// 	path: '/floor-layout', title: 'Floor Layout', icon: 'book', type: 'link', bookmark: true
		// },
		// {
		// 	path: '/masters/asset-documents', title: 'Asset Documents', icon: 'book', type: 'link', bookmark: true
		// },
		// {
		// 	path: '/reports/token-report', title: 'Token Report', icon: 'book', type: 'link', bookmark: true
		// },
		// {
		// 	path: '/reports/employee-report', title: 'Employee Report', icon: 'book', type: 'link', bookmark: true
		// },
		// {
		// 	title: 'Maintenance', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
		// 		{ path: '/maintenance', title: 'Maintenance', type: 'link' },
		// 		{ path: '/maintenance/rule', title: 'Rule', type: 'link' },
			
		// 	]
		// },
		// {
		// 	title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
		// 		{ path: '/reports/token-report', title: 'Token Report', type: 'link' },
		// 		{ path: '/reports/employee-report', title: 'Employee Report', type: 'link' },
			
		// 	]
		// },
		{
			title: 'Checklist', icon: 'message-square', type: 'sub', children: [
				// { path: '/check-list/check-list-titles', title: 'CheckListTitles', type: 'link' },
				{ path: '/check-list', title: 'CheckLists', type: 'link' },
				{ path: '/check-list/check-list-group', title: 'CheckList Group', type: 'link' },

				{ path: '/check-list/check-list-report', title: 'Report', type: 'link' },

				// { path: '/check-list/check-list-answer-type', title: 'Answer Type', type: 'link' },
				// { path: '/check-list/check-list-report', title: 'Report', type: 'link' },
				// { path: '/check-list/check-list-qstns', title: 'CheckListQuestions', type: 'link' },
			]
		},
		// {
		// 	title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success',  children: [
		// 		// { path: '/masters/floor', title: 'Floor', type: 'link' },
		// 		// { path: '/masters/room', title: 'Room', type: 'link' },
		// 		// { path: '/masters/camera', title: 'Camera', type: 'link' },
		// 		// { path: '/masters/employee', title: 'Employee', type: 'link' },
		// 		{ path: '/masters/user', title: 'User', type: 'link' },
		// 	]
		// },
		
		
		
	];
	EMPLOYEE_MENU_ITEMS :Menu[]= [

		{
			title: 'Checklist', icon: 'message-square', type: 'sub', children: [
				{ path: '/check-list', title: 'CheckLists', type: 'link' },
				// { path: '/check-list/check-list-report', title: 'Report', type: 'link' },
			]
		},
		// {
		// 	headTitle1: 'General', headTitle2: 'Ready Sample Page.',
		// },
		// {
		// 	path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link', bookmark: true
		// },
		// {
		// 	path: '/violation-dashboard', title: 'Violation', icon: 'home', type: 'link', bookmark: true
		// },
		// {
		// 	title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success', badgeValue: '2', children: [
		// 		{ path: '/masters/floor', title: 'Floor', type: 'link' },
		// 		{ path: '/masters/room', title: 'Room', type: 'link' },
		// 		{ path: '/masters/camera', title: 'Camera', type: 'link' },
		// 		{ path: '/masters/employee', title: 'Employee', type: 'link' },
		// 		{ path: '/masters/user', title: 'User', type: 'link' },
		// 	]
		// },
		
	];
	CHECKLIST_ITEMS :Menu[]= [
		// {
		// 	headTitle1: 'General', headTitle2: 'Ready Sample Page.',
		// },
		// {
		// 	path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link', bookmark: true
		// },
		{
			path: '/check-list-builder', title: 'Check List Builder', icon: 'book', type: 'link', bookmark: true

		},
		// {
		// 	title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success', badgeValue: '2', children: [
		// 		{ path: '/masters/floor', title: 'Floor', type: 'link' },
		// 		{ path: '/masters/room', title: 'Room', type: 'link' },
		// 		{ path: '/masters/camera', title: 'Camera', type: 'link' },
		// 		{ path: '/masters/employee', title: 'Employee', type: 'link' },
		// 		{ path: '/masters/user', title: 'User', type: 'link' },
		// 	]
		// },
		
	];
	MENUITEMS: Menu[] = [];
	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Error Pages', type: 'sub', active: true, children: [
				{ path: 'javascript:void(0);', title: 'Error Page 400', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 401', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 403', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 404', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 500', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 503', type: 'extLink' },
			]
		},
		{
			title: 'Authentication', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Login Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Video', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Simple Register', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Video', type: 'extLink' }
			]
		},
		{
			title: 'Usefull Pages', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Search Pages', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Unlock User', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Forgot Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Reset Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Maintenance', type: 'extLink' }
			]
		},
		{
			title: 'Email templates', type: 'sub', active: false, children: [
				{ path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html', title: 'Basic Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html', title: 'Basic With Header', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html', title: 'Ecomerce Template', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html', title: 'Email Template 2', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html', title: 'Ecommerce Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html', title: 'Order Success', type: 'extTabLink' }
			]
		},
		{
			title: 'Coming Soon', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Coming Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Video', type: 'extLink' }
			]
		},
	];

	LEVELMENUITEMS: Menu[] = [
		{
			path: 'javascript:void(0);', title: 'File Manager', icon: 'git-pull-request', type: 'extLink'
		},
		{
			title: 'Users', icon: 'users', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'All Users', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'User Profile', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Edit Profile', icon: 'users', type: 'extLink' },
			]
		},
		{ path: 'javascript:void(0);', title: 'Bookmarks', icon: 'heart', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Calender', icon: 'calendar', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Social App', icon: 'zap', type: 'extLink' }
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
