import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class ViolationDashboardService {

	public loadData = new BehaviorSubject<any>({});
  
  constructor(private http : HttpService) { }


  public getAll(url : string) : Observable<any>{
    return this.http.getApi(url).pipe(map((response) =>{
      return response;
    }))
    }
  public getById(url : string) : Observable<any>{
    return this.http.getApi(url).pipe(map((response) =>{
      return response;
    }))
    }
    public postData(url : string , requestObj) : Observable<any>{
      return this.http.postApi(url , requestObj).pipe(map((response) =>{
        return response;
      }))
      }
    public putData(url : string , requestObj) : Observable<any>{
      return this.http.putApi(url , requestObj).pipe(map((response) =>{
        return response;
      }))
      }

      public saveFormData(url : string , requestObj) : Observable<any>{
      return this.http.postFormDataApi(url , requestObj).pipe(map((response) =>{
        return response;
      }))
      }
}


