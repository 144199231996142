import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ILoginResponse } from 'src/app/auth/login/login.model';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit , OnDestroy {
  userDetails : ILoginResponse;
  userSubscription : Subscription;
  constructor(private router : Router , private sharedData : SharedDataService) { }
  ngOnDestroy(): void {
    if(this.userSubscription)this.userSubscription.unsubscribe();
}
  ngOnInit() {
    this.userSubscription =   this.sharedData.userDetails.subscribe((resdata: ILoginResponse) =>{
      if(resdata){
       this.userDetails = resdata;

       console.log(this.userDetails);
      }
      })

  }


  onLogOut(){
    this.router.navigate(['/auth']);
    localStorage.clear();
  }
}
