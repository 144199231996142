import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ILoginResponse } from 'src/app/auth/login/login.model';
import { apiUrl } from 'src/app/shared/constants/apiUrl-constants';
import { IApiResponse } from 'src/app/shared/constants/reponse.model';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { ITokenByStatus } from 'src/app/views/dashboard/violation-dashboard/violation-dashboard.model';
import { ViolationDashboardService } from 'src/app/views/dashboard/violation-dashboard/violation-dashboard.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  public openNotification: boolean = false;

  count : number = 0;


interVel : any;

  public subscription ;
  userSubscription : Subscription;
  constructor(private local : ViolationDashboardService,
    private sharedData : SharedDataService,
    private router : Router
    ) { }

  ngOnInit() {
  

    this.userSubscription =   this.sharedData.userDetails.subscribe((resdata: ILoginResponse) =>{
      if(resdata){

        if(resdata.roleId == 3){


          this.getViolations();
    
      this.interVel =    setInterval(()=> {
             this.getViolations() }, 20000);
            }
        }
       
      })

   

  }



  public getViolations(): void {
    if(localStorage.getItem(('userToken'))){
      this.local.getAll(apiUrl.GetTokensCountbyStatus + "?statusid=" + 1).subscribe((response: IApiResponse) => {
        this.count =  response.result.totalCount;
        // this.getAssignedTokens();
      })
    }
    
  }
  // public getAssignedTokens(): void {
  //   this.local.getAll(apiUrl.GetAssignedTokens).subscribe((response: IApiResponse) => {
  //     this.assignedViolations = response.result;
  //     this.count = this.count + response.result.length;

  //   })
  // }


  onNotificaion(){
  //  this.router.navigate(['/violation-dashboard']);
   this.sharedData.newTokenReceived.next(1);
  }
 
  ngOnDestroy(): void {
    if(this.subscription)
    this.subscription.unsubscribe();
    clearInterval(this.interVel);
  }



  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }




}
