<div class="media profile-media">
  <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="">
  <a (click)="onLogOut()"><img class=" logout b-r-10" src="assets/images/dashboard/logout.jpg" alt="" style="margin-left:5px ;"></a>
  <div class=" logout-1 media-body"><span>{{userDetails?.username}}</span>
    <p class="mb-0 font-roboto">{{userDetails?.roleName}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class=" logout-1 profile-dropdown onhover-show-div">
  <!-- <li><a href="#"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li> -->
  <!-- <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li> -->
  <!-- <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li> -->
  <!-- <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li><a (click)="onLogOut()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log Out</span></a></li>
</ul>