import { Routes } from '@angular/router';


export const content: Routes = [
  // {
  //   path: 'auth',
  //   loadChildren: () => import('../../auth/auth.module').then(m => m.AuthModule)
  // },
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'layout-builder',
    loadChildren: () => import('../../views/layout-builder/layout-builder.module').then(m => m.LayoutBuilderModule)
  },
  {
    path: 'masters',
    loadChildren: () => import('../../views/masters/masters.module').then(m => m.MastersModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../views/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../views/dashboard/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'violation-dashboard',
    loadChildren: () => import('../../views/dashboard/violation-dashboard/violation-dashboard.module').then(m => m.ViolationDashboardModule)
  },
  {
    path: 'check-list-dashboard',
    loadChildren: () => import('../../views/dashboard/check-list-dashboard/check-list-dashboard.module').then(m => m.CheckListDashboardModule)
  },
 
  {
    path: 'maintenance',
    loadChildren: () => import('../../views/maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('../../views/subscription/subscription.module').then(m => m.SubscriptionModule)
  },
  {
    path: 'floor-layout',
    loadChildren: () => import('../../views/floor-layout/floor-layout.module').then(m => m.FloorLayoutModule)
  },
  {
    path: 'check-list-builder',
    loadChildren: () => import('../../views/check-list-builder/check-list-builder.module').then(m => m.CheckListBuilderModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../views/settings/settings-routing.module').then(m => m.SettingsRoutingModule)
  },

  {
    path: 'check-list',
    loadChildren: () => import('../../views/check-list/check-list.module').then(m => m.CheckListModule)
  }

 
];
