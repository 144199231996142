export class Config {
    public static userDetails: any;
    public static organizationId: any = 0;
    public static roleId: any;
    public static isSuperAdmin: any = false;
    public static dateFormat : string ='dd-MM-yyyy' ;
    public static currencyFormat : string ='1.2-2';
  }


