import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ILoginResponse } from 'src/app/auth/login/login.model';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
	userDetails = new BehaviorSubject<any>({});
	newTokenReceived = new BehaviorSubject<any>({});

  constructor() { }
}
